<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Referentiels",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Referentiels",
      requests: [
        {
          title: "Gestion Des Entreprises Externe",
          route: "master_data.referentiels.extreme_business",
          description: "",
          icon: "extreme_business",
          guard: [
            "create_supplier",
            "edit_supplier",
            "delete_supplier",
            "view_supplier",
            "list_supplier",
            'create_ie',
            'edit_ie',
            'delete_ie',
            'view_ie',
            'list_ie',
            'create_extc',
            'edit_extc',
            'delete_extc',
            'view_extc',
            'list_extc'
          ],
          disabled: false,
        },
        {
          title: "Gestion des Structures",
          route: "master_data.referentiels.index",
          description: "",
          icon: "hierarchie",
          guard: [
            "create_hierarchie",
            "update_hierarchie",
            "read_hierarchie",
            "delete_hierarchie",
            "list_hierarchie",
          ],
          disabled: false,
        },
        {
          title: "Gestion des Infrastrutures",
          route: "master_data.referentiels.infrastructure",
          description: "",
          icon: "organization",
          guard: [
            "create_infra",
            "update_infra",
            "read_infra",
            "delete_infra",
            "list_infra",
          ],
          disabled: false,
        },
        {
          title: "Gestion Du Personnel",
          route: "master_data.referentiels.users",
          description: "",
          icon: "users",
          guard: [
            "create_employee",
            "edit_employee",
            "delete_employee",
            "view_employee",
            "list_employee",
          ],
          disabled: false,
        },
        {
          title: "Gestion de Stock",
          route: "master_data.referentiels.stock_management",
          description: "",
          icon: "box",
          guard: [
            "create_equipment",
            "edit_equipment",
            "delete_equipment",
            "view_equipment",
            "list_equipment",
            "create_product",
            "edit_product",
            "delete_product",
            "view_product",
            "list_product",
            "create_product_categ",
            "edit_product_categ",
            "delete_product_categ",
            "view_product_categ",
            "list_product_categ",
            'create_warehouse',
            'edit_warehouse',
            'delete_warehouse',
            'view_warehouse',
            'list_warehouse'
          ],
          disabled: false,
        },

        {
          title: "Gestion Des Chambres",
          route: "base.infrastructures.rooms",
          description: "",
          icon: "hosting",
          guard: [
            "create_hosting_room",
            "update_hosting_room",
            "read_hosting_room",
            "delete_hosting_room",
            "list_hosting_room",
          ],
          disabled: false,
        },
        {
          title: "Gestion Des Cuisines",
          route: "base.infrastructures.kitchen",
          description: "",
          icon: "kitchen",
          guard: [
            "create_kitchen",
            "update_kitchen",
            "read_kitchen",
            "delete_kitchen",
            "list_kitchen",
          ],
          disabled: false,
        },
        {
          title: "Parc Animalier",
          route: "master_data.referentiels.zoo.index",
          description: "",
          icon: "zoo",
          guard: [
            "create_animal",
            "edit_animal",
            "delete_animal",
            "view_animal",
            "list_animal",
          ],
          disabled: false,
        },
        {
          title: "Plantarium",
          route: "master_data.referentiels.plantarium.index",
          description: "",
          icon: "plants",
          guard: [
            "create_plant",
            "edit_plant",
            "delete_plant",
            "view_plant",
            "list_plant",
          ],
          disabled: false,
        },
        {
          title: "Gestion Des Equipments",
          route: "base.misc.equipments",
          description: "",
          icon: "equipment",
          guard: [
            "create_equipment",
            "edit_equipment",
            "delete_equipment",
            "view_equipment",
            "list_equipment",
          ],
          disabled: false,
        },
        {
          title: "Gestion Des comptes analytiques",
          route: "master_data.referentiels.analytics",
          description: "",
          icon: "accounting",
          guard: [
            "create_aac",
            "edit_aac",
            "delete_aac",
            "view_aac",
            "list_aac",
          ],
          disabled: false,
        },
      ],
      items: [
        {
          text: "Master Data",
          to: { name: "master_data" },
        },
        {
          text: "Referentiels",
          active: true,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row settings">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-xs-12 col-md-4 col-lg-4 setting-item"
                v-for="request in requests"
                v-bind:key="request.title"
              >
                <router-link
                  :class="{ 'disabledItem': !canAny(request.guard) || request.disabled }" :to="(!request.disabled && canAny(request.guard)) ? { name: request.route } : ''"
                >
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/menu/' +
                              request.icon +
                              '.png')
                          "
                          :alt="request.title"
                          :title="request.title"
                          class="rounded-0"
                        />
                        <h4 class="card-title">{{ request.title }}</h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none">
      <div
        class="col-lg-4 azmt-service-card"
        v-for="request in requests"
        v-bind:key="request.title"
      >
        <router-link
          v-if="canAny(request.guard)"
          :to="!request.disabled ? { name: request.route } : ''"
          :class="request.disabled ? 'disabledItem' : ''"
        >
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="8">
                <b-card-body :title="request.title"> </b-card-body>
              </b-col>
              <b-col md="4">
                <b-card-img
                  :src="
                    require('@/assets/images/base/menu/' +
                      request.icon +
                      '.png')
                  "
                  :alt="request.title"
                  :title="request.title"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
            </b-row>
          </b-card>
        </router-link>
      </div>
    </div>
  </Layout>
</template>
